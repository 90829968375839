<template>
	<div>
		<div v-if="!carouselMode" class="preview-images-thumb--imageshowroom--component-wrapper">
			<div v-for="(img, i) in images" :key="i" class="asset-preview-picture">
				<div class="rich-attachment" @click="showPicture(i)">
					<img :src="img.thumb" :alt="img.xid" />
				</div>
				<div class="veil" />

				<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
					<template #button-content>
						<i class="icon-arrow-down" />
					</template>
					<b-dropdown-item v-if="!hideCropOption" @click="handleCropPicture(img.xid, i)">
						<span>
							{{ FormMSG(9992323, 'Crop') }}
						</span>
					</b-dropdown-item>
					<b-dropdown-item v-if="!hideDeleteOption" @click="handleDeletePicture(img.xid)">
						<span class="text-danger">
							{{ FormMSG(2323, 'Delete picture') }}
						</span>
					</b-dropdown-item>
				</b-dropdown>
			</div>

			<span class="add-attachment-btn" @click="clickAddPicture">
				<b-spinner v-if="loading" variant="light" small />
				<i v-else class="icon-plus" />
			</span>
		</div>
		<div v-if="carouselMode">
			<b-row v-if="$screen.width > 576">
				<b-col>
					<carousel :pagination-enabled="false" :per-page="5" navigation-enabled>
						<slide>
							<div class="add-img" @click="clickAddPicture">
								<div class="center-content">
									<button type="button" class="btn-transparent">
										<component :is="getLucideIcon('PlusCircle')" :size="20" color="#00A09C" />
									</button>
								</div>
							</div>
						</slide>
						<slide v-for="(img, i) in carouselImages" :key="i">
							<div
								class="doc-list-package size-2"
								style="height: 100px; width: 100px; position: relative"
								@mouseenter="handleMouseEnter(img, i)"
								@mouseleave="handleMouseLeave(img, i)"
							>
								<div v-show="img.hoverAction" class="filename-carousel" v-b-tooltip.hover :title="filenames[i].name">
									{{ subSentence(filenames[i].name) }}
								</div>
								<div
									class="doc-list-package-action"
									style="max-height: 100px; width: 100px"
									:style="`height: ${img.hoverAction ? '100%' : '0'}`"
								>
									<div
										class="container-doc-list-package-action"
										:class="{
											'd-block': img.hoverAction,
											'd-none': !img.hoverAction
										}"
									>
										<div class="d-flex">
											<button type="button" class="btn-transparent" @click="showPicture(i)">
												<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="1.5" color="#00A09C" />
											</button>
											<button type="button" class="btn-transparent text-color-burning-tomato" @click="handleDeletePicture(img.xid)">
												<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" />
											</button>
										</div>
									</div>
								</div>
								<img :src="img.thumb" :alt="img.xid" class="location-slide-img" />
							</div>
						</slide>
					</carousel>
				</b-col>
			</b-row>
			<b-row v-if="$screen.width <= 576 && carouselImages.length > 0">
				<b-col cols="12" class="mb-3">
					<b-button block size="md" variant="outline-primary" @click="showPicture(0)">
						<component :is="getLucideIcon('FileImage')" :size="16" />
						{{ `${FormMSG(90, 'View')} ${carouselImages.length} ${FormMSG(91, 'pictures')}` }}
					</b-button>
				</b-col>
			</b-row>
			<b-row v-if="$screen.width <= 576">
				<b-col cols="12">
					<b-button block size="md" variant="outline-success" @click="clickAddPicture">
						{{ FormMSG(79, 'Add pictures') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { Carousel, Slide } from 'vue-carousel';

import { getImageChild } from '@/cruds/image-child.crud';

export default {
	name: 'PreviewImagesThumbComponent',
	mixins: [languageMessages, globalMixin],
	components: {
		Carousel,
		Slide
	},
	props: {
		...mapProps(['hideCropOption', 'hideDeleteOption', 'loading', 'carouselMode'], {
			type: Boolean,
			required: false,
			default: false
		}),
		images: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			isPwaCaptureLoading: false,
			carouselImages: [],
			filenames: []
		};
	},
	watch: {
		images: {
			handler(newVal) {
				let images = newVal;
				this.carouselImages = images.map((option) => {
					this.filenames.push({
						name: ''
					});

					return {
						...option,
						hoverAction: false
					};
				});
			},
			immediate: true
		}
	},
	methods: {
		subSentence(value) {
			const ext = value.split('.')[1];
			return value.substr(0, 5) + '... .' + ext;
		},
		handleMouseLeave(image, index) {
			image.hoverAction = false;
		},
		async handleMouseEnter(image, index) {
			if (this.filenames[index].name === '') {
				const imageChild = await getImageChild(image.xid.split('.')[0]);
				this.filenames[index].name = imageChild.originalFileName;
			}
			image.hoverAction = true;
		},
		/**
		 * @param {Number} index
		 */
		showPicture(index) {
			this.$emit('show-picture', index);
		},

		/**
		 * @param {String} img
		 * @param {Number} index
		 */
		handleCropPicture(img, index) {
			this.$emit('crop-picture', { xid, index });
		},

		/**
		 * @param {String} xid
		 */
		handleDeletePicture(xid) {
			this.$emit('delete-picture', xid);
		},

		clickAddPicture() {
			this.$emit('add-pic-click');
		}
	}
};
</script>

<style lang="scss" scoped>
.preview-images-thumb--imageshowroom--component-wrapper {
	width: 100%;
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	.asset-preview-picture .rich-attachment,
	span.add-attachment-btn {
		background: #252628;
		align-items: center;
		display: flex;
		justify-content: center;
		cursor: pointer;
		border-radius: 8px;
		border: 1px solid #424244;
	}

	.asset-preview-picture {
		margin: 0 8px 8px 0;
		position: relative;

		.rich-attachment,
		.dropdown {
			transition: opacity ease-in-out 300ms;
		}

		.rich-attachment {
			max-height: 64px;
			box-sizing: border-box;
			outline: 0;
			overflow: hidden;
			position: relative;
			cursor: pointer;
			& > img {
				max-height: 64px;
				display: block;
			}
		}

		& > .veil,
		& > .dropdown {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			opacity: 0;
		}
		& > .veil {
			pointer-events: none;
			z-index: 1;
			height: 100%;
			width: 100%;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.4);
		}

		& > .dropdown {
			z-index: 12;
			right: 15px;
			left: auto;
			background-color: rgba(255, 255, 255, 0.6);
			border-radius: 8px;
			height: 24px;
			width: 24px;
			margin: auto;
		}

		&:hover {
			& > .veil,
			& > .dropdown {
				opacity: 1;
			}
		}
	}

	span.add-attachment-btn {
		height: 64px;
		margin-bottom: 8px;
		width: 64px;
		border-style: dashed;
		i {
			color: #fff;
			font-size: 20px;
		}
	}
}
</style>
