<template>
	<div class="get_desktop_img_wrapper">
		<b-form-group :label="FormMSG(115, 'Select an image from your computer')" label-for="file-default">
			<b-form-file
				id="file-default"
				multiple
				:browse-text="FormMSG(113, 'Browse')"
				:placeholder="FormMSG(114, 'Choose a file from your computer')"
				:accept="rendAccept"
				@change="onFileSelected"
			/>
			<div class="text-danger" v-if="$has(unsupportedMessage)">{{ unsupportedMessage }}</div>
		</b-form-group>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import { arrInsertIf } from '@/shared/helpers';
import mapProps from '@/shared/vuePropsMapper';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'GetDesktopImgComponent',
	mixins: [languageMessages],
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		},
		accept: {
			type: String,
			required: false,
			default: 'image/*'
		},
		...mapProps(['acceptOffice', 'acceptApple'], {
			type: Boolean,
			required: false,
			default: false
		}),
		...mapProps(['acceptPdf', 'acceptDocx', 'acceptExlx', 'acceptPptx', 'acceptImg'], {
			type: Boolean,
			required: false,
			default: true
		}),
		unsupportedMimeTypes: {
			type: Array,
			required: false,
			default: () => ['video/*']
		}
	},
	data() {
		return {
			selectedImages: [],
			unsupportedMessage: null,

			fileNames: []
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		supportedMimeTypes() {
			return [
				...arrInsertIf(this.acceptImg, 'image/*'),
				...arrInsertIf(this.acceptPdf, 'application/pdf'),
				...arrInsertIf(this.acceptDocx, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword'),
				...arrInsertIf(this.acceptExlx, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'),
				...arrInsertIf(this.acceptPptx, 'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint'),
				...arrInsertIf(
					this.acceptOffice,
					'application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation'
				),
				...arrInsertIf(this.acceptApple, 'application/vnd.apple.numbers, application/vnd.apple.pages')
			];
		},

		/**
		 * @return {String}
		 */
		rendAccept() {
			return this.supportedMimeTypes.join(', ');
		}
	},
	watch: {
		selectedImages(val) {
			this.$emit('change', val);
		},
		fileNames(val) {
			this.$emit('change-filename', this.fileNames);
		}
	},
	methods: {
		/**
		 * @param {Object} $event
		 * { target, dataTransfer }
		 */
		onFileSelected($event) {
			const { files } = $event.target;
			if (isNil(files)) return;
			const keys = Object.keys(files);

			// keys.forEach(async (k) => await this.createImage(files[k]));
			keys.forEach(async (k) => {
				if (this.isFileSupported(files[k])) {
					await this.createImage(files[k]);
				} else {
					this.unsupportedMessage = this.FormMSG(116, 'The file format is not supported. Please upload a supported file.');
				}
			});
		},

		/**
		 * @param {File} file
		 * @return {Boolean}
		 */
		isFileSupported(file) {
			const fileType = file.type;
			const isSupported = this.supportedMimeTypes.some((type) => {
				if (type.includes('*')) {
					const [mainType] = type.split('/');
					return fileType.startsWith(mainType);
				}

				const supportedTypes = type.split(', ');
				return supportedTypes.some((supportedType) => fileType === supportedType.trim());
			});
			const isUnsupported = this.unsupportedMimeTypes.some((type) => fileType.startsWith(type));

			return isSupported && !isUnsupported;
		},

		/**
		 * @param {Object} file
		 */
		createImage(file) {
			// var image = new Image();
			this.fileNames.push(file.name);
			const reader = new FileReader();
			reader.onload = (e) => (this.selectedImages = e.target.result);
			reader.readAsDataURL(file);
		}
	}
};
</script>
