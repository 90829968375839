import { isNil, hasOwnProperty, isObj } from '~utils';
import { xidToImgObj } from '@/components/Packages/Captures/capture.utils';

const CapturesManager = function (self, initialState = {}) {
	if (!self) throw new Error('Capture is required.');

	this.capture = self;

	this.states = {
		capturedPicsList: [],
		editPicsList: [],
		imgsListPayload: [],
		options: { previewMode: 'card' },
		multiple: false,
		cropCaptureModalKey: 0,

		isCaptureModalOpen: false,
		isCropCaptureModalOpen: false,
		isWebCamCaptureModalOpen: false,

		imageForCrop: { index: 0, img: '', list: [] }
	};

	for (const prop in initialState) {
		if (!hasOwnProperty(initialState, prop)) return;
		this.states[prop] = initialState[prop];
	}

	/**
	 * @param {String} mutation
	 * @param {*} params
	 * @return {Function}
	 */
	this.commit = (mutation, params) => {
		const _m = Object.getPrototypeOf(this).mutations;
		if (isNil(_m[mutation])) throw new Error(`Mutation not found: ${mutation}`);
		return _m[mutation](this, params);
	};
};

CapturesManager.prototype.mutations = {
	/**
	 * @param {Object} managerContext
	 * @param {Boolean|null} options
	 */
	setOptions({ states }, options = {}) {
		states.options = Object.assign(states.options, options);
	},

	/**
	 * @param {Object} managerContext
	 * @param {Array|String} pictures
	 */
	setEditPictures({ states }, pictures) {
		if (isNil(pictures)) return;
		const __p = Array.isArray(pictures) ? pictures : [pictures];
		const res = __p.map((xid) => (isObj(xid) ? xid : xidToImgObj(xid)));
		states.editPicsList = res;
	},

	/**
	 * @param {Object} storeContext
	 * @param {Boolean|null} status
	 */
	toggleCaptureModal({ states }, status = null) {
		states.isCaptureModalOpen = isNil(status) ? !states.isCaptureModalOpen : status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	toggleWebCamCaptureModal({ states }, status) {
		states.isWebCamCaptureModalOpen = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {Boolean} status
	 */
	toggleCropCaptureModalOpen({ states }, status) {
		states.cropCaptureModalKey = states.cropCaptureModalKey + 1;
		states.isCropCaptureModalOpen = status;
	},

	/**
	 * @param {Object} managerContext
	 * @param {String} img
	 */
	addCapturedPicsList({ states }, img) {
		states.capturedPicsList.push(img);
	},

	/**
	 * @param {Object} managerContext
	 * @param {Number} index
	 */
	removeCapturedPicsListItem({ states }, index) {
		const list = states.capturedPicsList;
		if (isNil(list[index])) return;
		list.splice(index, 1);
		states.capturedPicsList = list;
	},

	/**
	 * @param {Object} managerContext
	 */
	initImgsListPayload({ states }) {
		const editPicsList = states.editPicsList.map((i) => i.xid);
		states.imgsListPayload = [...editPicsList, ...states.imgsListPayload];
	},

	/**
	 * @param {Object} managerContext
	 * @param {String} img
	 */
	setImgsListPayload({ states }, img) {
		states.imgsListPayload.push(img);
	},

	/**
	 * @param {Object} managerContext
	 */
	clearCapturedPicsList({ states }) {
		states.capturedPicsList = [];
	},

	replaceIndexCapturedPicsList({ states }, { index, img }) {
    if (isNil(img)) return;
    states.capturedPicsList.splice(index, 1, img)
	},

	/**
	 * @param {Object} managerContext
	 * @param {Object} item
	 */
	setImageForCrop({ states }, item) {
		states.imageForCrop = item;
	}
};

/**
 * sending emit on the table
 * @param {String} key
 * @param {*} content
 * @returns {this | *}
 */
CapturesManager.prototype.emit = function (key, content) {
	return this.capture.$emit(key, content);
};

/**
 * use store mutaions with 'commit' function
 * @param {String} name
 * @param {*} args
 */
CapturesManager.prototype.dispatch = function (name, ...args) {
	const { mutations, states, commit } = this;
	if (isNil(mutations[name])) throw new Error(`Mutation not found: ${name}`);
	const managerContext = { commit, states };
	mutations[name].apply(this, [managerContext].concat(args));
};

export default CapturesManager;
