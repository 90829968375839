<template>
	<div class="capture-imgs-list--component-wrapper">
		<ul class="list-container">
      <li v-for="(file, i) in processedCapturedPicsList" :key="i">
        <div class="captured-item">
          <div class="preview-container">
            <img v-if="file.fileType && file.fileType.startsWith('image')" :src="file.data" />
            <div v-else class="file-icon">
              <!-- Display a file icon based on the file type -->
              <i class="icon-docs" />
            </div>
          </div>

          <div class="actions">
            <b-button v-show="isCroppable(file.data)" size="sm" @click="setImageForCrop(file.data, i)">
              <ScissorsIconSVG />
            </b-button>
            <b-button variant="danger" size="sm" @click="removeCapturedPic(i)">
              <i class="icon-trash" />
            </b-button>
          </div>
        </div>
      </li>
		</ul>
	</div>
</template>

<script>
import { store } from '@/store';
import ScissorsIconSVG from '@/components/icons/scissors';

export default {
	name: 'CapturedImgsListComponent',
	components: { ScissorsIconSVG },
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {Array}
		 */
		capturedPicsList() {
			return this.manager.states.capturedPicsList;
		},

    /**
     * @param {Array}
     */
    processedCapturedPicsList() {
      return this.capturedPicsList.map((pic) => {
        const [typeInfo] = pic.match(/^data:([^;]*);/);
        const fileType = typeInfo ? typeInfo.replace('data:', '').replace(';', '') : null;
        return { fileType, data: pic };
      });
    },
	},
	methods: {
		/**
		 * @param {String} file
		 * @return {Boolean}
		 */
		isCroppable(file) {
      const [type] = file.split('/')
      return type == 'data:image'
		},

		/**
		 * @param {String} index
		 */
		removeCapturedPic(index) {
			this.manager.dispatch('removeCapturedPicsListItem', index);
		},

		/**
		 * @param {String} img
		 * @param {String} index
		 */
		setImageForCrop(img, index) {
			this.manager.dispatch('setImageForCrop', {
				img,
				index,
				type: 'cropBeforeUpload'
			});
			store.state.imageToCrop = img;
			this.manager.dispatch('toggleCropCaptureModalOpen', true);
		},
	}
};
</script>

<style lang="scss" scoped>
.capture-imgs-list--component-wrapper {
	ul {
		li {
			width: 100%;
			height: auto;

			.captured-item {
				width: 100%;
				display: flex;
				height: 64px;
				justify-content: space-between;
				.preview-container {
          display: flex;
          align-items: center;
					border-radius: 8px;
					overflow: hidden;
					max-height: 64px;
					max-width: 64px;

					img {
						object-fit: contain;
						width: 100%;
						height: 100%;
					}

          .file-icon .icon-docs {
            font-size: 20px;
          }
				}
				.actions {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					button:not(:first-child) {
						margin-left: 15px;
					}
				}
			}
		}
	}
}
</style>
