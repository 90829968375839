import { isNil } from '@/shared/utils';

const pathImg = process.env.VUE_APP_PATH_IMG_URL;
const imagethumb = process.env.VUE_APP_PATH_IMG_THUMB_URL;

/**
 * @param {String} xid
 * @return  {Object}
 */
export const xidToImgObj = (xid) => {
	const fullPath = pathImg + xid;
	const thumbPath = imagethumb + xid;
	return { xid, src: fullPath, thumb: thumbPath };
};

export const b64toFile = (b64Data, filename, contentType) => {
	const sliceSize = 512;
	const byteCharacters = atob(b64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const file = new File(byteArrays, filename, {
		type: contentType
	});
	return file;
};

/**
 * @param {String} base64
 * @return {String}
 */
export const getExtensionFromBase64 = (base64) => {
	const parts = base64.split(';');
	const format = parts[0].split('/')[1];
	return format;
};

/**
 * @param {String} base64
 * @return {Blob}
 */
export const base64ToBlob = (base64) => {
	const parts = base64.split(';base64,');
	const contentType = parts[0].split(':')[1];
	const raw = window.atob(parts[1]);
	const rawLength = raw.length;
	const uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
};

// /**
//  * @param {String} base64String
//  * @return {Boolean}
//  */
// export const isBase64ImgFile = (base64String) => {
// 	if (isNil(base64String)) return false;
// 	const img = new Image();
// 	img.src = base64String;
// 	return img.complete && img.naturalWidth !== 0;
// };

/**
 * Check if a file can be cropped by checking its MIME type.
 * @param {String} src - base64 string or URL
 * @return {Promise<Boolean>}
 */
export const isImageFile = async (src) => {
  if (!src) return false;

  // Utility function to get the MIME type from base64
  const getMimeTypeFromBase64 = (base64) => {
    const regex = /^data:(.+);base64/;
    const match = regex.exec(base64);
    return match ? match[1] : null;
  };

  // Utility function to get the MIME type from URL
  const getMimeTypeFromUrl = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.headers.get('Content-Type');
    } catch (error) {
      console.error('Error fetching URL:', error);
      return null;
    }
  };

  const isDataURL = /^data:image/.test(src);
  const mimeType = isDataURL
    ? getMimeTypeFromBase64(src)
    : await getMimeTypeFromUrl(src);

  // Check if the MIME type is a supported image type
  const supportedImageTypes = ['image/png', 'image/jpeg', 'image/webp'];
  return supportedImageTypes.includes(mimeType);
}
