var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      class: _vm.rendComponentClass,
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "ok-variant": "success",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-title": _vm.FormMSG(29801, "Save"),
        "cancel-title": _vm.FormMSG(212302, "Cancel"),
        title: _vm.FormMSG(26700, "Picture capture"),
        "ok-disabled": _vm.isLoading,
        "cancel-disabled": _vm.isLoading,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.saveCapturedPics.apply(null, arguments)
        },
        cancel: function ($event) {
          return _vm.handleCloseModal("cancel")
        },
        close: function ($event) {
          return _vm.handleCloseModal("close")
        },
        hidden: function ($event) {
          return _vm.handleCloseModal("hidden")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-ok",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _vm.isLoading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(29801, "Save")) +
                      "\n\t\t\t"
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c(
        "div",
        { ref: "containerCaptureModal" },
        [
          _c(
            "b-row",
            [
              _vm.capturedPicsList.length > 0
                ? _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("CapturedImgsList", {
                        attrs: { manager: _vm.manager },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                [
                  _c("DesktopImgUpload", {
                    attrs: {
                      acceptDocx: false,
                      acceptExlx: false,
                      acceptPptx: false,
                      manager: _vm.manager,
                    },
                    on: {
                      change: _vm.handleDesktopImageSelected,
                      "change-filename": _vm.handleChangeFileName,
                    },
                  }),
                  _c("separator", { attrs: { label: _vm.FormMSG(1, "or") } }),
                  _c(
                    "div",
                    { staticClass: "centered_content full push_t_20" },
                    [
                      _c(
                        "b-button",
                        { on: { click: _vm.openWebCamCaptureModal } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(342234, "Use webcam")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      ref: "modal",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        "ok-variant": "success",
                        size: "lg",
                        title: _vm.FormMSG(200, "Picture capture"),
                        "ok-title": _vm.FormMSG(201, "Save"),
                        "cancel-title": _vm.FormMSG(202, "Cancel"),
                      },
                      on: {
                        ok: _vm.saveWebCamCapture,
                        cancel: function ($event) {
                          _vm.isWebCamCaptureModalOpen = false
                        },
                        hidden: function ($event) {
                          _vm.isWebCamCaptureModalOpen = false
                        },
                      },
                      model: {
                        value: _vm.isWebCamCaptureModalOpen,
                        callback: function ($$v) {
                          _vm.isWebCamCaptureModalOpen = $$v
                        },
                        expression: "isWebCamCaptureModalOpen",
                      },
                    },
                    [
                      _c("CaptureWebCam", {
                        attrs: { manager: _vm.manager },
                        on: { change: _vm.handleCaptureChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }