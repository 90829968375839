var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "capture-imgs-list--component-wrapper" }, [
    _c(
      "ul",
      { staticClass: "list-container" },
      _vm._l(_vm.processedCapturedPicsList, function (file, i) {
        return _c("li", { key: i }, [
          _c("div", { staticClass: "captured-item" }, [
            _c("div", { staticClass: "preview-container" }, [
              file.fileType && file.fileType.startsWith("image")
                ? _c("img", { attrs: { src: file.data } })
                : _c("div", { staticClass: "file-icon" }, [
                    _c("i", { staticClass: "icon-docs" }),
                  ]),
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCroppable(file.data),
                        expression: "isCroppable(file.data)",
                      },
                    ],
                    attrs: { size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.setImageForCrop(file.data, i)
                      },
                    },
                  },
                  [_c("ScissorsIconSVG")],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.removeCapturedPic(i)
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon-trash" })]
                ),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }